function flyBox() {

  let offset = '',
      count = '',
      scrollTop = '',
      x = '';

  $(window).scroll(function () {
    $('.fly-box').each(function () {
      scrollTop = $(window).scrollTop();
      count = $(this).offset().top - window.innerHeight;
      offset = $(this).offset().top;
      x = 'translateY(-' + Math.round(scrollTop / (offset / 50)) + '%)';
      if (scrollTop >= count && scrollTop <= offset) {
        $(this).css({'transform': x});
      }
    });
  });

}
