function scrollToSection() {

    // scroll to top
    $('.toTop').on('click', function (e) {
        e.preventDefault();
        $('.burger-menu__item, .header__nav').removeClass('is-active');
        $('html, body').animate({scrollTop: 0}, 500);
        return false;
    });

    // page scroll active section
    var sections = $('.scroll');

    $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop();
        sections.each(function () {


            if (window.innerWidth <= 767) {
                var top = $(this).offset().top - 50;
            } else if (window.innerWidth <= 1280) {
                var top = $(this).offset().top - 60;
            } else {
                var top = $(this).offset().top - 70;
            }
            var bottom = top + $(this).outerHeight();
            if (cur_pos >= top && cur_pos <= bottom) {
                $('.header__nav-link').removeClass('is-active');

                if (!this.id) {
                    return;
                }
                $('.header__nav-link[href="/#' + $(this).attr('id') + '"]').addClass('is-active');
            }
        });
    });

    // click nav item active section
    if (location.pathname === '/') {
        $('.header__nav-link.header__nav-section, .footer__nav-link, .jobs-link').on('click', function (e) {
            e.preventDefault();
            var el = $(this),
                id = this.hash;

            $('.header__nav-link').removeClass('is-active');
            el.addClass('is-active');

            if (window.innerWidth <= 767) {
                $('.burger-menu__item, .header__nav').removeClass('is-active');
                $('html, body').animate({
                    scrollTop: $(id).offset().top - 49
                }, 500);
            } else if (window.innerWidth <= 1280) {
                $('html, body').animate({
                    scrollTop: $(id).offset().top - 59
                }, 500);
            } else {
                $('html, body').animate({
                    scrollTop: $(id).offset().top - 69
                }, 500);
            }
            return false;

        });
    }
}
