function modal() {

  let modalClass = document.querySelector('.modal'),
      modalClose = document.querySelector('.modal-close');

  if (!modalClose) {
      return;
  }

  // hide modal on click close
  modalClose.addEventListener('click', function() {
    modalClass.classList.remove('is-active');
  });

  // hide modal on click outside
  modalClass.addEventListener('click', function(e) {
    let target = e.target;
    if (!target.closest('.modal-success')) {
      modalClass.classList.remove('is-active');
    }
  });
}

function modalEvent() {

  let modalEventClass = document.querySelector('.modal-event'),
      modalEventClose = document.querySelector('.modal-event__close'),
      modalBackdrop = document.querySelector('.modal-backdrop'),
      modalPromoCopy = document.querySelector('.modal-event__promo-copy'),
      elementBody = document.querySelector('body'),
      modalCollapse = document.querySelector('.modal-collapse');

  if (!modalEventClose) {
    return;
  }

  function showModal() {
    modalEventClass.classList.add('is-active');
    elementBody.classList.add('modal-open');
    modalBackdrop.style.display = 'block';
    modalBackdrop.classList.add('show');
  }

  function hideModal() {
    modalEventClass.classList.remove('is-active');
    elementBody.classList.remove('modal-open');
    modalBackdrop.classList.remove('show');
    modalCollapse.classList.add('show');

    setTimeout(function() {
      modalBackdrop.style.display = 'none';
    }, 100);
  }

  // hide modal on click close
  modalEventClose.addEventListener('click', function() {
    hideModal();
  });

  // hide modal on click outside
  modalEventClass.addEventListener('click', function(e) {
    let target = e.target;
    if (!target.closest('.modal-event__body')) {
      hideModal();
    }
  });

  // modal collapse trigger
  modalCollapse.addEventListener('click', function() {
    modalCollapse.classList.remove('show');
    showModal();
  });

  // show modal when page is loaded
  setTimeout(function() {
    showModal();
  }, 3000);

  // copy text from promo code block
  modalPromoCopy.addEventListener('click', function () {
    let copyText = document.getElementById("modal-promo-code");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  });
}

modalEvent();
