function header() {

  $(window).scroll(function() {
    if ($(window).scrollTop() > 1) {
      $('.header').addClass('is-active');
    } else {
      $('.header').removeClass('is-active');
    }
  });

  if (pageYOffset > 1) {
    $('.header').addClass('is-active');
  } else {
    $('.header').removeClass('is-active');
  }

  let burgerBtn = document.querySelector('.burger-menu'),
      headerNav = document.querySelector('.header__nav'),
      burgerItem = document.querySelectorAll('.burger-menu__item');

  burgerBtn.addEventListener('click', function() {
    headerNav.classList.toggle('is-active');
    headerNav.classList.add('transition');
    for (let i = 0; i < burgerItem.length; i++) {
      burgerItem[i].classList.toggle('is-active');
    }
  });

}
