function slick() {

  $('.reviews-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  let reviews = $('.reviews-slider');
  let controls = $('.reviews-controls');

  reviews.on('init', function (event, slick) {
    setSlideCount(slick.slideCount + 1);
    setCurrentSlideNumber(slick.currentSlide + 1);
  }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    setCurrentSlideNumber(nextSlide + 1);
  });

  function setSlideCount(slideCount) {
    controls.find('.total-slides').text(slideCount);
  }

  function setCurrentSlideNumber(currentSlide) {
    if (window.innerWidth >= 768) {
      controls.find('.current-slide').text(currentSlide + 1);
    } else {
      controls.find('.current-slide').text(currentSlide);
    }
  }

  $('.reviews-next').click(function () {
    reviews.slick('slickNext');
  });

  $('.reviews-prev').click(function () {
    reviews.slick('slickPrev');
  });

  // init team slider
  if (window.innerWidth <= 767) {
    $('#team-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      dots: false,
      arrows: true
    });
  }

}
