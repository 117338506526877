function handler(event) {

    let hash = typeof event === 'string' ? event : event.target.hash;

    if (!hash)
        return;

    let tag = $(hash);

    if (window.innerWidth <= 767) {
        if (tag.length) {
            let offset = tag.offset().top - 49;
            $('html, body').stop().animate({
                scrollTop: offset
            }, 500);
        }
    } else if (window.innerWidth <= 1280) {
        if (tag.length) {
            let offset = tag.offset().top - 59;
            $('html, body').stop().animate({
                scrollTop: offset
            }, 500);
        }
    } else {
        if (tag.length) {
            let offset = tag.offset().top - 69;
            $('html, body').stop().animate({
                scrollTop: offset
            }, 500);
        }
    }

}

if (location.pathname === '/') {
    $('.nav-job-link').on('click', () => {
        handler(location.hash);
    });
}
