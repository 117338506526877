$(function() {

  header();
  scrollToSection();
  slick();
  attachFile();
  flyBox();
  modal();

  AOS.init({
    once: true,
  });

  handler(location.hash);

  $('#preloader').fadeOut('slow', function() {
    $(this).remove();
  });

});
